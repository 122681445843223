import React from 'react'

import './../styles/pageSpecific/services.scss'
import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import AngledContainer from '../components/UI/Containers/AngledContainer'
import CapabilityVideo from '../components/UI/Capabilities/CapabilityVideo/CapabilityVideo'

import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ThreeBoxRow from '../components/UI/Images/ThreeBoxRow/ThreeBoxRow'
import ServiceIcon from './../images/icons/capabilities/ProgramManagement.inline.svg'

const ProgramManagementPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Program',
    title2: 'Management',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Expertise Supporting{' '}
              <span>Government &amp; Civilian Customers</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                We develop second-to-none technical, financial, contract,
                process, communications, and acquisition strategies in support
                of the warfighter. Our cross-functional teams provide extensive
                expertise to translate customer objectives into actionable
                tasks.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityVideo
            url="https://www.youtube.com/embed/yqGSu4Qniug?yt:cc=on"
            title="Precise Program Management Informational Video"
          />
        </SectionContainer>

        {/* Why Us */}
        <div id="detailsDisplayServices_Wrap">
          <AngledContainer
            id="detailsDisplayServices"
            color="navy"
            type="fullWidth"
            svgColor="grayToNavy"
          >
            <SectionHeader
              color="white"
              alignment="center"
              size="med"
              trim="true"
            >
              Providing <span>Expertise In</span>
            </SectionHeader>
          </AngledContainer>
          <ThreeBoxRow
            overlaying={true}
            boxContent={{
              first: {
                img: 'carbon:events',
                text: 'Strategically Composed Cross-functional Teams',
              },
              second: {
                img: 'carbon:badge',
                text: 'Expertly Trained Professionals',
              },
              third: {
                img: 'carbon:lifesaver',
                text: 'Skillful Support of the Customer',
              },
            }}
          />
        </div>
        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Program Management"
            ServiceIcon={ServiceIcon}
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default ProgramManagementPage
